import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Card, Table } from "react-bootstrap";
// import "./index.module.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../css/style.css";
// import "../css/style-001.css";

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FiberManualRecord, Stop } from '@mui/icons-material';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import UndoIcon from '@mui/icons-material/Undo';

import UploadNewPDF from "./UploadNewPDF";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DoneIcon from '@mui/icons-material/Done';

export default function UploadPDF() {
    const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [activeCompany, setactiveCompany] = useState("WOS PDF upload");
  const [file, setFile] = useState([]);
  const [latestDoc, setlatestDoc]:any = useState("");

  useEffect(()=>{
    var requestOptions:any = {
        method: 'GET',
        // headers: {
        //   "Content-Type": "application/json",
        //   'Access-Control-Allow-Origin': '*'
        // },
        // // mode: 'no-cors',
        // redirect: 'follow'
      };
      
      fetch(
        // "https://hrmanualapi.recruitment.ai/get-latest-pdf",
        "https://reportmgt-api.worldofstones.in/get-latest-pdf",
        // "http://localhost:1070/get-latest-pdf",
         requestOptions)
        .then(response => response.json())
        .then(result => setlatestDoc(result?.result))
        .catch(error => console.log('error', error));
  }, [])

  async function onSubmit(event: any) {
    event.preventDefault();
    if(file.length>0){
    setIsLoader(true);
    try {
        var formdata = new FormData();
        formdata.append("pdf", file[0]);
      const response = await fetch(
        // "https://hrmanualapi.recruitment.ai/vector-store",
        "https://reportmgt-api.worldofstones.in/vector-store",
        // "http://localhost:1070/vector-store",
        {
          method: "POST",
          // headers: {
          //   // "Content-Type": "application/json",
          //   'Access-Control-Allow-Origin': '*',
          // },
          // // mode: 'no-cors',
          // redirect: 'follow',
          body: formdata,
        }
      );
        // console.log("response", response)
      const data = await response.json();
      console.log("data", data)
      if (response.status === 200) {
        setIsLoader(false);
        navigate("/pdf")
      }
      if (response.status !== 200) {
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }

    } catch (error) {
    }
  }
  }

  const mplogoImg = require("../images/logo_MP.png");
  const chatGPTNew = require("../images/chatGPTNew.png");
  return (
    <div className="container-fuild chatGPTDivBox">
      <h1 className="d-none">
        <title>OpenAI {activeCompany}</title>
        <link rel="icon" href="/chatGPT.PNG" />
      </h1>

      <main className="main">
        <div className="header">
          <img src={mplogoImg} className="icon" /> 
        </div>

        <h3 className="welcomeTxt">Welcome to {activeCompany}</h3>
        <h5>{latestDoc && <PictureAsPdfIcon style={{color: "red", fontSize: "40px"}} />} {latestDoc}{latestDoc && <DoneIcon style={{color: "green", fontSize: "40px"}}/>}</h5>
        <div className="chatListBox">
          <form onSubmit={onSubmit} style={{ width: "100%" }}>
            <UploadNewPDF setFile={setFile} />
              
            <button
            className="submitBtn"
            type="submit"
            value={isLoader ? "Wait" : "1Submit"}
            disabled={isLoader}
            >
            {isLoader && (
                <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                ></span>
            )}
            {isLoader ? " Loading.." : "Submit"}
            </button>
          </form>
        </div>
      </main>
    </div>
  );
}
  
