import { DropzoneArea } from 'material-ui-dropzone';
import { useState, useEffect } from "react";
export default function UploadNewPDF({setFile}:any) {
  
  return (
    <div style={{maxWidth: "600px", width: "100%", height: "auto", margin: "15px auto"}}>
    <DropzoneArea 
     onChange={(files:any) => setFile(files)}
     filesLimit={1}
     maxFileSize={5000000000}
    />
    </div>
  );
}
