import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

// Define a type for the slice state
interface AuthState {
  firstName: string | null;
  lastName: string | null;
  token: string | null;
  email: string | null;
}

// Define the initial state using that type
const initialState: AuthState = {
  firstName: null,
  lastName: null,
  token: null,
  email: null,
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGoogleSignUpUser: (
      state,
      action: PayloadAction<{
        firstName: string;
        lastName: string;
        token: string;
        email: string;
      }>
    ) => {
      localStorage.setItem(
        "user",
        JSON.stringify({
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
        })
      );
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.token = action.payload.token;
    },
    logout: (state) => {
      // localStorage.removeItem('persist');
      localStorage.clear();
      state.token = null;
      state.email = null;
    },
  },
});
export const selectAuth = (state: RootState) => state.auth;
export const { setGoogleSignUpUser,  logout } = authSlice.actions;

export default authSlice.reducer;
