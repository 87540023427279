import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleAuthSignUp from "./components/googleSignup";
import GoogleAuthWrapper from "./authentication/SignupWrapper";
import HomeDashboard from "./components/HomeDashboard";
import HomeChat from "./components/HomeChat";
import HomeChatPDF from "./components/HomeChatPDF";
import HomeChatTable from "./components/HomeChatTable";
import UploadPDF from "./components/UploadPDF";
import CompanyName from "./components/CompanyName";
import OrderJSON from "./components/OrderJSON";
import HistoryChatPDF from "./components/HistoryChatPDF";
import VariantCode from "./components/VariantCode";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeDashboard />} />
        <Route path="/chat" element={<HomeChat />} />
        <Route path="/tab" element={<HomeChatTable />} />
        <Route path="/pdf" element={<HomeChatPDF />} />
        <Route path="/history" element={<HistoryChatPDF />} />
        <Route path="/upload" element={<UploadPDF />} />
        <Route path="/company" element={<CompanyName />} />
        <Route path="/order" element={<OrderJSON />} />
        <Route path="/variant" element={<VariantCode />} />
        {/* <Route path="/" element={<GoogleAuthSignUp />} /> */}
        {/* <Route path="/googleAuthWrapper" element={<GoogleAuthWrapper />} />
        <Route path="/HomeDashboard" element={<HomeDashboard />} /> */}
 
      </Routes>
    </div>
  );
}

export default App;
