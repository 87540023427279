import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Card, Table } from "react-bootstrap";
// import "./index.module.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../css/style.css";
// import "../css/style-001.css";

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FiberManualRecord, Stop } from '@mui/icons-material';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import UndoIcon from '@mui/icons-material/Undo';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function Home() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [activePrompt, setactivePrompt] = useState("World of Stones PDF Chat");
  const [activeCompany, setactiveCompany] = useState("World of Stones PDF Chat");
  const messagesEndRef = useRef(null);

  const [inputQuestion, setinputQuestion] = useState("please provide detailed JSON data of all order");

  const [questionAndAnswers, setQuestionAndAnswers] = useState<any>([]);
  const [chatHistory, setChatHistory] = useState<any>([]);

  const scrollToBottom = () => {
    const abp: any = messagesEndRef.current;
    abp?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [questionAndAnswers]);

  const handleChange = (e: any) => {
    console.log("(e.target.value);", e.target.value);
    setinputQuestion(e.target.value);
  };


  const clearForm = () => {
    setinputQuestion("");
    resetTranscript()
  };
  async function onSubmit(event: any) {
    event.preventDefault();
    if(event.target[0].value){
    setIsLoader(true);
    const userInputQuery = event.target[0].value;
    // console.log("activePrompt ", activePrompt)
    try {
      const response = await fetch(
        // "https://hrmanualapi.recruitment.ai/ask-question",
        "https://reportmgt-api.worldofstones.in/get-company",
        // "http://localhost:1070/get-company",
        // "https://hrmanualpy.recruitment.ai/ask",//python
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // 'Access-Control-Allow-Origin': '*',
          },
        //   mode: 'no-cors',

          body: JSON.stringify({ userQuery: userInputQuery, chatHistory: chatHistory }),
          // body: JSON.stringify({ question: userInputQuery }),//python
        }
      );

      const data = await response.json();
      if (response.status === 200) {
        setIsLoader(false);
        setinputQuestion("");
        resetTranscript()
        let x: any = document.getElementById("chatGPTtext");
        x.value = "";
      }
      if (response.status !== 200) {
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }
      console.log("data", data)
      const item = { Question: userInputQuery, Answer: data?.result?.status };
      setChatHistory(data?.result?.chatHistory)
      // const item = { Question: userInputQuery, Answer: data?.qa_result?.result };//python


      setQuestionAndAnswers((questionAndAnswers: any) => [
        ...questionAndAnswers,
        item,
      ]);
    } catch (error) {
    }
  }
  }


  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    setinputQuestion(transcript);
  }, [transcript]);

  const stopListen = ()=>{
    SpeechRecognition.stopListening();
  }

  const startListen = ()=>{
    SpeechRecognition.startListening({continuous: true});
  }
  

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const mplogoImg = require("../images/logo_MP.png");
  const chatGPTNew = require("../images/chatGPTNew.png");
  return (
    <div className="container-fuild chatGPTDivBox">
      <h1 className="d-none">
        <title>OpenAI {activeCompany}</title>
        <link rel="icon" href="/chatGPT.PNG" />
      </h1>

      <main className="main">
        {/* <div className="header">
          <img src={mplogoImg} className="icon" /> 
        </div> */}

        <h3 className="welcomeTxt">Welcome to {activeCompany}</h3>
        <div className="chatListBox">
            {/* <Form.Group controlId="SpentTable" className=""> */}
              <ul className="autoChatList">
                {questionAndAnswers?.map((item: any, index: any) => (
                  <li key={index}>
                    <span style={{width: "100%"}}>
                    <label className="question">
                      <div className="container">
                        <b>
                         MP
                        </b>
                        <span>
                          {""}{item.Question[0].toUpperCase() +
                            item.Question.substring(1)}{""}
                         
                        </span>
                      </div>
                    </label></span>
                    <span style={{width: "100%"}}>
                    <label className="answer">
                      <div className="container">
                        <b>
                          <img src={chatGPTNew} className="chatImg" />
                        </b>
                        <pre>{item.Answer.substring(0)}</pre>
                      </div>
                    </label></span>
                  </li>
                ))}
                <div ref={messagesEndRef} />
              </ul>

              <form onSubmit={onSubmit} style={{ width: "100%" }}>
              <div className="footerDiv">
                <div className="container">
                  <Form.Control
                    type="text"
                    placeholder="We're here to help if you have any questions!!"
                    onChange={handleChange}
                    value={inputQuestion}
                    id="chatGPTtext"
                    name="Question"
                    className="inputClass"
                  />
                  <div className="buttons-align">
                  <button
                    className="clearBtn"
                    type="reset"
                    value="Clear"
                    onClick={clearForm}
                    title="Redo"
                  >
                    <UndoIcon />
                  </button>
                  <button className='micStart2' type="button" title={listening?`Stop`:`Speak` } onClick={
                    listening?
                      stopListen
                    :
                      function(e) {
                            startListen()
                        }
                      }>
                      {listening?
                        <MicIcon />
                            :
                        <MicOffIcon />
                      }
                  </button>
                  <button
                    className="submitBtn"
                    type="submit"
                    value={isLoader ? "Wait" : "1Submit"}
                    disabled={isLoader}
                  >
                    {isLoader && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    {isLoader ? " Loading.." : "Submit"}
                  </button>
                  <button
                    className=""
                    type="button"
                    title="Change PDF"
                    onClick={()=>{
                        navigate("/upload")
                    }}
                  >
                    <PictureAsPdfIcon />
                  </button>
                  </div>
                </div>
              </div>
            {/* </Form.Group> */}
          </form>
        </div>
      </main>
    </div>
  );
}
  
