import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://codebotapi.mobileprogramming.com/",
  }),
  endpoints: (builder) => ({
    googleSignupUser: builder.mutation({
      query: (body: {
        firstName: string | any;
        lastName: string | any;
        token: string | any;
        email: string | any;
        language: string | any;
      }) => {
        return {
          url: "/user",
          method: "post",
          body,
        };
      },
    }),
    getLanguage: builder.query({
      query: () => `/languages`,
    }),
  }),
});
export const { useGoogleSignupUserMutation, useGetLanguageQuery } = authApi;
